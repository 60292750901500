import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import React, { useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { useStoreFilters } from 'store/store-filters';
import { buildPropertyCategoryPath } from 'utils/search/convertValues/convertPropertyType';
import { EVENTS } from 'utils/tracking/events';
import { logEvent } from 'utils/tracking/gtm';

import MoreAccordion from './MoreAccordion/MoreAccordion';
import PriceAccordion from './PriceAccordion/PriceAccordion';
import RoomsAccordion from './RoomsAccordion/RoomsAccordion';
import SearchAccordion from './SearchAccordion/SearchAccordion';

export default function SearchModal({ open, setOpen }) {
  const [active, setActive] = useState(false);

  const router = useRouter();
  const store = useStoreFilters();
  const { setFilters, setLocationBBox } = store;

  const pathname = usePathname();

  const [localLocation, setLocalLocation] = useState(
    store?.filters.location || null,
  );
  const [localBBox, setLocalBBox] = useState({
    lat: store?.locationBBox?.lat ?? store?.filters?.lat,
    long: store?.locationBBox?.long ?? store?.filters?.long,
    topLat: store?.locationBBox?.topLat ?? store?.filters?.topLat,
    bottomLat: store?.locationBBox?.bottomLat ?? store?.filters?.bottomLat,
    leftLong: store?.locationBBox?.leftLong ?? store?.filters?.leftLong,
    rightLong: store?.locationBBox?.rightLong ?? store?.filters?.rightLong,
  });
  const [localLocationKey, setLocalLocationKey] = useState(
    store?.filters.locationKey || null,
  );
  const [localBeds, setLocalBeds] = useState(store?.filters.bedrooms || null);
  const [localBdExact, setLocalBdExact] = useState(
    store?.filters.bdexact || null,
  );
  const [localBaths, setLocalBaths] = useState(
    store?.filters.bathrooms || null,
  );
  const [localBaExact, setLocalBaExact] = useState(
    store?.filters.baexact || null,
  );
  const [localMinPrice, setLocalMinPrice] = useState(
    store?.filters.minPrice || '',
  );
  const [localMaxPrice, setLocalMaxPrice] = useState(
    store?.filters.maxPrice || '',
  );
  const [localPropertyCategory, setLocalPropertyCategory] = useState(
    store?.filters.propertyCategory || null,
  );
  const [localRoomForRent, setLocalRoomForRent] = useState(
    store?.filters.roomForRent || null,
  );
  const [localPets, setLocalPets] = useState(store?.filters.pets ?? null);
  const [localPetPolicy, setLocalPetPolicy] = useState(
    store?.filters.petPolicy || false,
  );
  const [localVerifiedFlag, setLocalVerifiedFlag] = useState(
    store?.filters.verifiedFlag || false,
  );

  // hack for syncing with store on open
  useEffect(() => {
    const filters = store.getFilters();

    setLocalLocation(filters.location);
    setLocalLocationKey(filters.locationKey);
    setLocalBeds(filters.bedrooms || null);
    setLocalBdExact(filters.bdexact || null);
    setLocalBaths(filters.bathrooms || null);
    setLocalBaExact(filters.baexact || null);
    setLocalMinPrice(filters.minPrice || '');
    setLocalMaxPrice(filters.maxPrice || '');
    setLocalPropertyCategory(filters.propertyCategory || null);
    setLocalRoomForRent(filters.roomForRent ?? null);
    setLocalPets(filters.pets ?? null);
    setLocalPetPolicy(filters.petPolicy || false);
    setLocalVerifiedFlag(filters.verifiedFlag ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSearchClick = () => {
    const getNewPath = () => {
      const paramsObj = {
        ...(localMinPrice && { minPrice: localMinPrice }),
        ...(localMaxPrice && { maxPrice: localMaxPrice }),
        ...(localRoomForRent !== null && {
          roomForRent: localRoomForRent,
        }),
        ...(localRoomForRent === '' && { roomForRent: 'Both' }),
        ...(localVerifiedFlag && { verified: localVerifiedFlag }),
        ...((localPets || localPets === false) && { pets: localPets }),
        ...(localPetPolicy && { petPolicy: localPetPolicy }),
      };

      let path = `/search/${localLocationKey}`;

      if (localPropertyCategory?.length) {
        path = path.concat(buildPropertyCategoryPath(localPropertyCategory));
      }
      if (localBeds) path = path.concat(`/bedrooms-${localBeds}`);
      if (localBeds && !localBdExact) path = path.concat(`+`);
      if (localBaths) path = path.concat(`/bathrooms-${localBaths}`);
      if (localBaths && !localBaExact) path = path.concat(`+`);
      if (Object.keys(paramsObj).length > 0) {
        const queryPath = new URLSearchParams(paramsObj);
        path = path.concat(`?${queryPath.toString()}`);
      }

      return path.toLowerCase();
    };

    const newPath = getNewPath();
    if (pathname !== newPath) {
      NProgress.start();
    }

    // fix it later
    const shallowRouting = pathname.startsWith(newPath.split('?')[0]);

    const searchterm =
      store?.filters?.searchterm &&
      store.filters.searchterm.startsWith(localLocationKey)
        ? store.filters.searchterm.replace(
            /^([a-z-]+)(-[0-9]+)?$/,
            `$1-${Date.now()}`,
          )
        : localLocationKey;

    setFilters({
      location: localLocation,
      locationKey: localLocationKey,
      bedrooms: localBeds,
      bdexact: localBeds ? !!localBdExact : null,
      bathrooms: localBaths,
      baexact: localBaths ? !!localBaExact : null,
      minPrice: localMinPrice,
      maxPrice: localMaxPrice,
      propertyCategory:
        localPropertyCategory?.length > 0 ? localPropertyCategory : null,
      roomForRent: localRoomForRent,
      pets: localPets,
      petPolicy: localPetPolicy,
      verifiedFlag: localVerifiedFlag,
      shallowRouting,
      topLat: localBBox.topLat,
      bottomLat: localBBox.bottomLat,
      leftLong: localBBox.leftLong,
      rightLong: localBBox.rightLong,
      lat: localBBox.lat,
      long: localBBox.long,
      searchterm,
    });
    setOpen(false);

    logEvent(EVENTS.SEARCH_LISTINGS, {
      filters: {
        location: localLocation,
        locationKey: localLocationKey,
        bedrooms: localBeds,
        bdexact: localBeds ? localBdExact : null,
        bathrooms: localBaths,
        baexact: localBaths ? localBaExact : null,
        minPrice: localMinPrice,
        maxPrice: localMaxPrice,
        propertyCategory:
          localPropertyCategory?.length > 0 ? localPropertyCategory : null,
        roomForRent: localRoomForRent,
        pets: localPets,
        petPolicy: localPetPolicy,
        verifiedFlag: localVerifiedFlag,
      },
    });

    router.push(newPath, newPath, { shallow: shallowRouting });
  };

  if (!open) {
    return null;
  }

  return (
    <FocusLock>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        className="fixed inset-0 backdrop-blur backdrop-brightness-50 h-screen w-screen z-50 pt-10 pb-20 overflow-y-scroll"
        role="dialog"
        aria-modal="true"
        onClick={(e) => {
          if (e.target !== e.currentTarget) return;
          setOpen(false);
        }}
        onKeyDown={(event) => event.key === 'Escape' && setOpen(false)}
      >
        <div className="transition-transform -translate-y-1 ease-in-out delay-150	flex flex-col p-2 m-auto items-center drop-shadow-2xl justify-center w-full sm:w-[475px] md:w-[500px] lg:w-[600px] bg-[#F7F7FB] rounded-[32px]">
          <SearchAccordion
            store={store}
            location={localLocation}
            onResultSelect={(res) => {
              // eslint-disable-next-line no-unsafe-optional-chaining
              const [rightLong, bottomLat, leftLong, topLat] = res?.bbox;
              const location = {
                rightLong,
                bottomLat,
                leftLong,
                topLat,
                lat: res?.lat,
                long: res?.lng,
              };
              setLocalBBox(location);
              setLocationBBox(location);
              setLocalLocation(res?.name);
              setLocalLocationKey(res?.key);
            }}
          />
          <RoomsAccordion
            store={store}
            active={active}
            setActive={setActive}
            beds={localBeds}
            setBeds={setLocalBeds}
            bdExact={localBdExact}
            setBdExact={setLocalBdExact}
            baths={localBaths}
            setBaths={setLocalBaths}
            baExact={localBaExact}
            setBaExact={setLocalBaExact}
          />
          <PriceAccordion
            store={store}
            active={active}
            setActive={setActive}
            minPrice={localMinPrice}
            setMinPrice={setLocalMinPrice}
            maxPrice={localMaxPrice}
            setMaxPrice={setLocalMaxPrice}
          />
          <MoreAccordion
            active={active}
            setActive={setActive}
            propertyCategory={localPropertyCategory}
            setPropertyCategory={setLocalPropertyCategory}
            roomForRent={localRoomForRent}
            setRoomForRent={setLocalRoomForRent}
            pets={localPets}
            setPets={setLocalPets}
            petPolicy={localPetPolicy}
            setPetPolicy={setLocalPetPolicy}
            verifiedFlag={localVerifiedFlag}
            setVerified={setLocalVerifiedFlag}
          />

          <button
            type="submit"
            tabIndex="0"
            aria-label="Search listings button"
            className={`px-6 w-full h-[48px] text-white rounded-full bg-purple ${
              localLocation || store?.filters.location ? '' : 'opacity-25'
            }`}
            onClick={onSearchClick}
            disabled={!localLocation && !store?.filters.location}
          >
            Search Listings
          </button>
        </div>
      </div>
    </FocusLock>
  );
}
