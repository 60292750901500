import { titleCase } from 'utils/formatting';

export function convertPropertyType(types) {
  const typesArray = types.split(',').map((el) => titleCase(el));
  return {
    propertyCategory: typesArray,
  };
}

const PROPERTY_TYPES = {
  apartments: 'Apartment',
  houses: 'House',
  mobile: 'Mobile',
  manufactured: 'Mobile',
  places: ['Apartment', 'House', 'Mobile'],
};

export function convertPathPropertyType(path) {
  const typesArray = [];
  path.split('-').forEach((el) => {
    if (PROPERTY_TYPES[el]) {
      if (Array.isArray(PROPERTY_TYPES[el])) {
        typesArray.push(...PROPERTY_TYPES[el]);
      } else {
        typesArray.push(PROPERTY_TYPES[el]);
      }
    }
  });
  return {
    propertyCategory: typesArray,
  };
}

function getPathType(propertyType) {
  const type = propertyType.toLowerCase();

  return type === 'mobile' ? 'mobile-home' : type;
}

export function buildPropertyCategoryPath(propertyTypes) {
  let places = '';
  if (propertyTypes.length > 2) {
    places = 'places';
  } else if (propertyTypes.length === 2) {
    places = `${getPathType(propertyTypes[0])}s-and-${getPathType(
      propertyTypes[1],
    )}s`;
  } else {
    places = `${getPathType(propertyTypes[0])}s`;
  }
  return `/${places}-for-rent`;
}

export default convertPropertyType;
